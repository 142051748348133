import { Box } from '@mui/material'
import { mobileSideSpace, useUiContext } from '@orus.eu/pharaoh'
import type { ReactElement, ReactNode } from 'react'

export type SmallScreenMarginProps = {
  children: ReactNode
}

export function SmallScreenMargin({ children }: SmallScreenMarginProps): ReactElement {
  const largeScreen = useUiContext() === 'desktop'
  return largeScreen ? (
    <>{children}</>
  ) : (
    <Box sx={{ marginLeft: mobileSideSpace, marginRight: mobileSideSpace }}>{children}</Box>
  )
}
